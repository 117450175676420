import TreeObject from "../components/HomePage/Tree";
import React from "react";
import Header from "../components/Header";
import Contact from "../components/HomePage/Contact";
import {MdMailOutline, MdPhone} from "react-icons/md";
import {HiMiniMapPin} from "react-icons/hi2";

export default function ContactPage() {
    return <div className="flex flex-row items-start justify-center gap-5 w-full px-5">
        <div className="sticky top-0">
            <TreeObject/>
        </div>
        <div className="flex flex-col justify-center gap-5 pt-5 mb-14 w-[70%]">
            <Header/>
            <div className="flex flex-row justify-between w-full pt-14">
                <div className="flex flex-row gap-1">
                    <HiMiniMapPin className="fill-green" size={25}/>
                    <div className="flex flex-col gap-1">
                        <p className="font-bold text-xl text-green">
                            Адрес нашего склада
                        </p>
                        <p className="select-text">
                            г. Балашиха, квартал Западная Промзона,<br/>шоссе Энтузиастов, 2.
                        </p>
                        <p className="text-black/50">Время работы:</p>
                        <p className="select-text">пн-пт: с 9:00 до 18:00, сб: с 9:00 до 14:00</p>
                    </div>
                </div>
                <div className="flex flex-row gap-1">
                    <MdPhone size="25px" className="text-green flex-shrink-0"/>
                    <div className="flex flex-col">
                        <p className="font-bold text-xl text-green">Телефоны</p>
                        <p className="select-text">
                            8 (925) 050-27-77<br/>
                            8 (903) 000-29-72
                        </p>
                    </div>
                </div>
                <div className="flex flex-row items-start gap-1">
                    <MdMailOutline size="25px" className="text-green flex-shrink-0"/>
                    <div className="flex flex-col">
                        <p className="font-bold text-xl text-green">Почта</p>
                        <p className="select-text">
                            banki-kryshki@bk.ru
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}