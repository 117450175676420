import {YMaps, Map, Placemark} from '@pbe/react-yandex-maps';
import {useMediaQuery} from "@react-hook/media-query";

export default function MapContainer() {
    const isMobile = useMediaQuery('(max-width: 767px)');

    return (
        <div className="flex w-dvw items-end">
            <YMaps>
                <Map defaultState={{center: [55.793308, 37.899374], zoom: 13}} className="w-full h-96">
                    <Placemark
                        geometry={[55.793308, 37.899374]}/>
                </Map>
            </YMaps>
        </div>
    )
}