import TreeObject from "../components/HomePage/Tree";
import React from "react";
import Header from "../components/Header";
import StorageInfo from "../components/HomePage/StorageInfo";

export default function Storage() {
    return <div className="flex flex-row items-start justify-center gap-5 w-full px-5">
        <div className="sticky top-0">
            <TreeObject/>
        </div>
        <div className="flex flex-col justify-center gap-5 pt-5 mb-14 w-[70%]">
            <Header/>
            <StorageInfo/>
        </div>
    </div>
}