import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from "./pages/HomePage";
import Products from "./pages/Products";
import Storage from "./pages/Storage";
import Deliver from "./pages/Deliver";
import ContactPage from "./pages/Contacts";

export default function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage/>}/>
                <Route path="/order" element={<Products/>}/>
                <Route path="/storage" element={<Storage/>}/>
                <Route path="/deliver" element={<Deliver/>}/>
                <Route path="/contact" element={<ContactPage/>}/>
            </Routes>
        </Router>
    )
}