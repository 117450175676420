import {AiOutlineClose} from "react-icons/ai";
import React from "react";
import {motion, AnimatePresence} from "framer-motion"
import {RemoveScroll} from "react-remove-scroll";

const Modal = ({title, body, footer, setOpen}) => {
    return (
        <RemoveScroll>
            <AnimatePresence>
                <motion.div
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    className="fixed backdrop-blur-sm flex flex-row justify-center items-center z-20 w-full h-full top-0 left-0 bg-black/20">
                    <div className="bg-white rounded-md flex flex-col gap-5 p-7 relative max-w-[70%]">
                        {title}
                        {body}
                        {footer}
                        <AiOutlineClose className="absolute right-2 top-2 cursor-pointer" onClick={() => setOpen(false)}/>
                    </div>
                </motion.div>
            </AnimatePresence>
        </RemoveScroll>
    )
}

export default Modal