import React, {useEffect, useState} from "react";
import {Tree} from 'primereact/tree';
import {useMediaQuery} from '@react-hook/media-query';
import {motion} from "framer-motion";
import {AiOutlineClose} from "react-icons/ai";
import ButtonCatalog from "./ButtonCatalog";
import {useNavigate} from "react-router-dom";
import axios from "axios";
export default function TreeObject({isOpen, setOpen, button}) {
    const navigate = useNavigate()
    const isMobile = useMediaQuery('(max-width: 1200px)');
    const [treeData, setTree] = useState(null)

    async function getCats(){
        try {
            const {data} = await axios.get('https://api.ansaratracker.store/api/tree/')
            setTree(data.map((x, index)=>({
                key: `0-${index}`, label: x
            })))
        }
        catch (e){
        }
    }

    useEffect( () => {
        getCats()
    }, []);


    let expandeds = {}
    for (var el in treeData) {
        expandeds[treeData[el].key] = true
    }
    const sidebar = {
        open: (height = 1000) => ({
            clipPath: `circle(150dvh at 40px 40px)`, transition: {
                type: "spring", stiffness: 20, restDelta: 2
            }
        }), closed: {
            clipPath: "circle(0px at 40px 40px)", transition: {
                duration: 0.5
            }
        }
    };
    const [selectedKey, setSelectedKey] = useState('');

    if (isMobile) {
        return <motion.div
            initial={false}
            animate={isOpen ? "open" : "closed"}
            className="bg-white absolute left-0 top-0 z-20"
            variants={sidebar}
        >
            <div className="flex relative">
                <AiOutlineClose size="30px"
                                className="absolute left-0 top-0 z-50 active:text-green active:scale-95 transition mt-10 ml-5 cursor-pointer"
                                onClick={() => setOpen(!isOpen)}/>
                <Tree value={treeData} expandedKeys={expandeds} selectionKeys={selectedKey}
                      onSelectionChange={(e) => {
                          setSelectedKey(e.value)
                          navigate(`#${e.value}`)
                      }}
                      className="text-lg lg:text-sm sticky mt-10 px-3 w-[100dvw] p-0 pt-7 max-h-[100dvh] overflow-y-auto"
                      pt={{
                          label: "outline-green cursor-pointer active:outline-none shadow-none flex w-full transition",
                          node: {
                              children: "active:bg-yellow-300"
                          },
                          root: "outline-none",
                          container: "outline-none",
                      }}/>
            </div>
        </motion.div>
    }
    return <div className="flex sticky top-4 flex-col gap-4 mr-2 mt-4 px-3 w-96 h-screen">
        {button ? (<ButtonCatalog className="flex"/>) : null}
        <Tree value={treeData} expandedKeys={expandeds} selectionKeys={selectedKey}
              onSelectionChange={(e) => {
                  setSelectedKey(e.value)
              }}
              onNodeClick={(e) => {
                  const element = document.getElementById(`${e.node.key}`);
                  console.log(element)
                  if (element) {
                      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                  }
                  else {
                      const currentPath = window.location.pathname
                      if(currentPath !== '/order') {
                          navigate('/order')
                      }
                  }
              }}
              className="text-sm  rounded-md p-0 pt-3 max-h-[90dvh] overflow-y-auto"
              pt={{
                  label: "outline-green cursor-pointer active:outline-none shadow-none flex w-full transition",
                  node: {
                      children: "active:bg-yellow-300"
                  },
                  root: "outline-none",
                  container: "outline-none",
              }}/>
    </div>


}