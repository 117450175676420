import Logo from "./Logo"
import {FaWhatsapp} from "react-icons/fa6";
import {HiOutlineMenu} from "react-icons/hi";
import {useNavigate} from "react-router-dom";

const Header = ({isOpen, setOpen}) => {
    const navigate = useNavigate()
    return (
        <div className="flex flex-row bg-white rounded-md px-2 items-center gap-2 md:gap-5 w-full justify-between">
            <div className="flex flex-row items-center gap-1">
                <HiOutlineMenu className="block lg:hidden active:text-green active:scale-95 transition" size="30px"
                               onClick={() => setOpen(!isOpen)}/>
                <div className="py-2 items-center drop-shadow-md flex flex-row gap-2 cursor-pointer" onClick={() => navigate('/')}>
                    <Logo fill="limegreen" className="size-16"/>
                    <div className="flex flex-col font-semibold justify-center">
                        <p className="text-2xl">ДЕМЕТРА</p>
                        <p className="font-medium">Закатай лето в банку</p>
                    </div>
                </div>
            </div>
            <div className="flex-row items-center gap-3 font-light hidden lg:flex">
                <a href="/order">Каталог</a>
                <a href="/storage">Склад</a>
                <a href="/deliver">Доставка</a>
                <a href="/contact">Контакты</a>
            </div>
            <div>
                <p className="font-light text-xl">8 925 050 - 27 - 77</p>
                <div className="flex flex-row items-center w-full justify-between text-lg">
                    <FaWhatsapp className="text-green"/>
                    <p className="text-green text-medium">
                        Написать в WhatsApp
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Header