import { FaRegArrowAltCircleRight } from "react-icons/fa";
import {useNavigate} from "react-router-dom";

const ButtonCatalog = ({className}) => {
    const defaultClass = className || "flex justify-center items-center w-full"
    const navigate = useNavigate()
    return (
        <div className={defaultClass}>
            <div onClick={() => navigate('/order')} className="flex flex-row active:scale-[98%] items-center gap-5 bg-green text-white py-2 w-full justify-center rounded-md cursor-pointer text-xl font-semibold transition">
                <p>Перейти в каталог</p>
                <FaRegArrowAltCircleRight/>
            </div>
        </div>
    )
}

export default ButtonCatalog