import TableCreations from './TableCreations';

function PriceTable({ amount, setAmount, banks, setBanks, setDescription }) {
  return (
    <div className='flex flex-col gap-5 z-0 w-full'>
      {banks.map((x, index) => (
        <div key={index} className='flex flex-col gap-5 w-full' id={`0-${index}`}>
          <div className='w-full sticky top-36 z-20 py-2 rounded-xl px-4 bg-white/80 backdrop-blur-lg'>
            <h1 className='text-xl font-semibold'>{x.title}</h1>
          </div>
          <TableCreations
            amount={amount}
            x={x}
            index={index}
            setDescription={setDescription}
            setBanks={setBanks}
            banks={banks}
            setAmount={setAmount}
          />
        </div>
      ))}
    </div>
  );
}

export default PriceTable;
