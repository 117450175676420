import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {MdCurrencyRuble} from "react-icons/md";
import {FiMinus, FiPlus} from "react-icons/fi";
import {MdNoPhotography} from "react-icons/md";

const TableCreations = ({banks, setBanks, setAmount, amount, setDescription, index, x}) => {
    const imageBodyTemplate = (product) => {
        return product.images ? <img
                src={'http://api.ansaratracker.store' + product.images[0].image}
                alt={product.img}
                className="shadow-2 border-round flex-shrink-0 h-20 rounded-md"/>
            : <div className="h-20 w-20 flex justify-center items-center rounded-md bg-[#f5f4f2]">
                <MdNoPhotography size={50} className="opacity-40"/>
            </div>
    };

    const nameTemplate = (product) => {
        return <div className="flex flex-col">
            <p className="line-clamp-2">{product.description}</p>
            <p className="text-black/30">{product.quantity_in_pack} шт. в упаковке</p>
        </div>
    }

    const priceTemplate = (product, key) => {
        return <div className="flex flex-col">
            <div className="flex flex-row items-center">
                <p className="flex-shrink-0">{parseInt(product[key])}</p>
                <MdCurrencyRuble className="flex-shrink-0"/>
                <p className="flex-shrink-0"> / шт.</p>
            </div>
        </div>
    }
    const quantityTemplate = (rowData, bankIndex, itemIndex) => {

        const increment = (value) => {
            console.log(value)
            const newBanks = [...banks];
            newBanks[bankIndex].items[itemIndex].orderCount += value;
            setBanks(newBanks);
            updateAmount(newBanks);
        };

        const decrement = (value) => {
            const newBanks = [...banks];
            if (newBanks[bankIndex].items[itemIndex].orderCount >= value) {
                newBanks[bankIndex].items[itemIndex].orderCount -= value;
                setBanks(newBanks);
            }
            updateAmount(newBanks);
        };

        const updateAmount = (banks) => {
            let totalAmount = 0;
            banks.forEach((bank) => {
                bank.items.forEach((item) => {
                    totalAmount += item.orderCount * getPricePerUnit(item);
                });
            });
            setAmount(totalAmount);
        };

        const getPricePerUnit = (item) => {
            const {orderCount, price1} = item;
            if (orderCount <= 0) {
                return 0;
            }
            return price1;
        };

        const handleChange = (target, type) => {
            const value = parseInt(target.target.value.replace('шт.', '').replace('уп.', ''))
            const newBanks = [...banks];
            if (type === 1) {
                if (!isNaN(parseInt(value))) {
                    newBanks[bankIndex].items[itemIndex].orderCount = parseInt(value);
                } else {
                    newBanks[bankIndex].items[itemIndex].orderCount = 0;
                }
                setBanks(newBanks);
                updateAmount(newBanks);
            }
            if (type === 2) {

                if (!isNaN(parseInt(value * newBanks[bankIndex].items[itemIndex].quantity_in_pack))) {
                    newBanks[bankIndex].items[itemIndex].orderCount = parseInt(value * newBanks[bankIndex].items[itemIndex].quantity_in_pack);
                } else {
                    newBanks[bankIndex].items[itemIndex].orderCount = 0;
                }
                setBanks(newBanks);
                updateAmount(newBanks);
            }
        }

        return (
            <div className="flex flex-row items-center gap-2">
                <div
                    className="flex items-center gap-1 rounded-md border-[1px] w-full border-black/30 justify-between">
                    <button className="flex-shrink-0 w-6 h-6 justify-center items-center flex">
                        <FiMinus className="text-green active:scale-95" onClick={(e) => {
                            e.preventDefault()
                            decrement(1)
                        }}/></button>
                    <input className="w-full flex-shrink-1 text-center"
                           onChange={(e) => handleChange(e, 1)}
                           value={`${Math.floor(rowData.orderCount)} шт.`}/>
                    <button className="flex-shrink-0 w-6 h-6 justify-center items-center flex">
                        <FiPlus className="text-green active:scale-95" onClick={(e) => {
                            e.preventDefault()
                            increment(1)
                        }}/></button>
                </div>
                <div
                    className="flex items-center gap-1 rounded-md border-[1px] w-full border-black/30 justify-between">
                    <button className="flex-shrink-0 w-6 h-6 justify-center items-center flex">
                        <FiMinus className="text-green active:scale-95" onClick={(e) => {
                            e.preventDefault()
                            decrement(rowData.quantity_in_pack)
                        }}/></button>
                    <input className="w-full flex-shrink-1 text-center"
                           onChange={(e) => handleChange(e, 2)}
                           value={`${Math.floor(rowData.orderCount / rowData.quantity_in_pack)} уп.`}/>
                    <button className="flex-shrink-0 w-6 h-6 justify-center items-center flex">
                        <FiPlus className="text-green active:scale-95" onClick={(e) => {
                            e.preventDefault()
                            increment(rowData.quantity_in_pack)
                        }}/></button>
                </div>
            </div>
        );
    }
    return (
        <DataTable onRowClick={() => setDescription(x.items[index])} value={x.items}
                   tableStyle={{minWidth: '950px'}} className="custom-table bg-[#f5f4f2]"
                   rowClassName="w-full justify-between py-0">
            <Column style={{flexShrink: 0, display: "flex", alignItems: "center"}}
                    className="py-1 px-1"
                    body={imageBodyTemplate}></Column>
            <Column field="name" body={nameTemplate} className="w-96 py-1"></Column>
            <Column className="flex items-center w-44 py-0" field="price1"
                    style={amount < 10000 ? {background: '#00b95633'} : {}}
                    body={(data) => priceTemplate(data, 'price1')}></Column>
            <Column className="flex items-center w-44 py-0" field="price2"
                    style={amount > 9999 && amount < 100000 ? {background: '#00b95633'} : {}}
                    body={(data) => priceTemplate(data, 'price2')}></Column>
            <Column className="flex items-center w-44 py-0" field="price3"
                    style={amount > 99999 ? {background: '#00b95633'} : {}}
                    body={(data) => priceTemplate(data, 'price3')}
            ></Column>
            <Column
                className="flex items-center ml-5 pr-1"
                body={(rowData, options) => quantityTemplate(rowData, index, options.rowIndex)}
                style={{flexShrink: 0}}
            ></Column>
        </DataTable>
    )
}

export default TableCreations