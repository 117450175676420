const Logo = ({fill, className}) => {
    const fillColor = fill ? fill : "#07911c"
    const classNameSvg = className ? className : ''
    return (
        <svg width="399" height="399" className={classNameSvg} viewBox="0 0 399 399" xmlns="http://www.w3.org/2000/svg">
            <g id="g1">
                <path id="path1" fill={fillColor} fillRule="evenodd" stroke="none"
                      d="M 138 71 C 138 43.385773 115.614235 21 88 21 C 60.385765 21 38 43.385773 38 71 C 38 98.614227 60.385765 121 88 121 C 115.614235 121 138 98.614227 138 71 Z"/>
                <path id="-" fill={fillColor} fillRule="evenodd" stroke="none"
                      d="M 324 50 C 324 22.385773 301.614227 0 274 0 C 246.385757 0 224 22.385773 224 50 C 224 77.614227 246.385757 100 274 100 C 301.614227 100 324 77.614227 324 50 Z"/>
                <path id="--5" fill={fillColor} fillRule="evenodd" stroke="none"
                      d="M 198 121 C 198 104.431458 184.568542 91 168 91 C 151.431458 91 138 104.431458 138 121 C 138 137.568542 151.431458 151 168 151 C 184.568542 151 198 137.568542 198 121 Z"/>
                <path id="--6" fill={fillColor} fillRule="evenodd" stroke="none"
                      d="M 155 198 C 155 181.431458 141.568542 168 125 168 C 108.431458 168 95 181.431458 95 198 C 95 214.568542 108.431458 228 125 228 C 141.568542 228 155 214.568542 155 198 Z"/>
                <path id="--7" fill={fillColor} fillRule="evenodd" stroke="none"
                      d="M 212 258 C 212 241.431458 198.568542 228 182 228 C 165.431458 228 152 241.431458 152 258 C 152 274.568542 165.431458 288 182 288 C 198.568542 288 212 274.568542 212 258 Z"/>
                <path id="--8" fill={fillColor} fillRule="evenodd" stroke="none"
                      d="M 284 220 C 284 203.431458 270.568542 190 254 190 C 237.431458 190 224 203.431458 224 220 C 224 236.568542 237.431458 250 254 250 C 270.568542 250 284 236.568542 284 220 Z"/>
                <path id="--9" fill={fillColor} fillRule="evenodd" stroke="none"
                      d="M 274 138 C 274 121.431458 260.568542 108 244 108 C 227.431458 108 214 121.431458 214 138 C 214 154.568542 227.431458 168 244 168 C 260.568542 168 274 154.568542 274 138 Z"/>
                <path id="path2" fill={fillColor} fillRule="evenodd" stroke="none"
                      d="M 106.371208 183.334503 L 130.402756 176.443573 L 110.563988 107.257568 L 86.532448 114.148499 Z"/>
                <path id="path3" fill={fillColor} fillRule="evenodd" stroke="none"
                      d="M 161.600632 271.352661 L 161.665115 246.352737 L 89.6912 246.167084 L 89.626717 271.166992 Z"/>
                <path id="--2" fill={fillColor} fillRule="evenodd" stroke="none"
                      d="M 259.269989 246.618744 L 235.711044 238.253601 L 211.628067 306.079041 L 235.187027 314.444183 Z"/>
                <path id="--3" fill={fillColor} fillRule="evenodd" stroke="none"
                      d="M 303.415588 202.425293 L 319.44455 183.240082 L 264.210968 137.093262 L 248.182007 156.278458 Z"/>
                <path id="--4" fill={fillColor} fillRule="evenodd" stroke="none"
                      d="M 245.774323 78.720673 L 230.060745 59.297974 L 174.105743 104.567322 L 189.819321 123.990021 Z"/>
                <path id="path4" fill={fillColor} fillRule="evenodd" stroke="none"
                      d="M 399 220 C 399 192.385773 376.614227 170 349 170 C 321.385773 170 299 192.385773 299 220 C 299 247.614243 321.385773 270 349 270 C 376.614227 270 399 247.614243 399 220 Z"/>
                <path id="path5" fill={fillColor} fillRule="evenodd" stroke="none"
                      d="M 262 349 C 262 321.385773 239.614227 299 212 299 C 184.385757 299 162 321.385773 162 349 C 162 376.614227 184.385757 399 212 399 C 239.614227 399 262 376.614227 262 349 Z"/>
                <path id="path6" fill={fillColor} fillRule="evenodd" stroke="none"
                      d="M 100 256 C 100 228.385773 77.614235 206 50 206 C 22.385763 206 0 228.385773 0 256 C 0 283.614227 22.385763 306 50 306 C 77.614235 306 100 283.614227 100 256 Z"/>
            </g>
        </svg>
    )
}


export default Logo