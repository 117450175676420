import {PlayText} from "./svg/PlayText";
import {IoPlayOutline} from "react-icons/io5";
import {motion} from 'framer-motion';
import {GrZoomIn} from "react-icons/gr";

export default function StorageInfo() {
    return (
        <div className="flex flex-col gap-14 justify-center items-center mt-10 lg:w-[873px] w-full">
            <div className="flex max-w-[95dvw] w-full flex-row justify-between gap-5 md:gap-0">
                <h1 className="font-bold text-lg md:text-4xl">
                    Свой склад <br className="hidden md:block"/> площадью 1800м²
                </h1>
                <p className="font-thin text-sm text-black/50">
                    На нашем складе в наличии более 21 млн. единиц продукции. <br/> Мы можем сформировать и отправить
                    ваш
                    заказ в течение 2 часов.
                </p>
            </div>
            <div className="flex flex-col gap-14">
                <div className="w-full relative flex justify-center items-center">
                    <img src="/home/home2.png" alt="preview2" className="w-[90%]"/>
                    <div className="absolute right-[-4%] md:right-0 top-[25%] md:top-[30%] flex justify-center items-center">
                        <div className="bg-green rounded-full active:scale-[45%] scale-[75%] md:scale-100 md:hover:scale-105 transition md:active:scale-95">
                            <motion.div
                                animate={{rotate: 360}}
                                transition={{duration: 25, repeat: Infinity, ease: "linear"}}>
                                <PlayText/>
                            </motion.div>
                            <IoPlayOutline className="absolute left-[35%] top-[30%] text-white size-14"/>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <div className="flex flex-row gap-2">
                        <div className="rounded-xl hover:scale-105 transition active:scale-95">
                            <img src="/home/galery1.png" alt="galery1"/>
                        </div>
                        <div className="rounded-xl hover:scale-105 transition active:scale-95">
                            <img src="/home/galery2.png" alt="galery2"/>
                        </div>
                        <div className="rounded-xl hover:scale-105 transition active:scale-95">
                            <img src="/home/galery3.png" alt="galery3"/>
                        </div>
                    </div>
                    <div className="flex flex-row gap-2 items-center">
                        <GrZoomIn className="text-black/50"/>
                        <p className="text-black/50 font-thin">Нажмите на изображение для увеличения</p>
                    </div>
                </div>
            </div>
        </div>
    )
}