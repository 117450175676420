import {FiCheckCircle} from "react-icons/fi";

export default function HomeVideo() {
    return (
        <div className="rounded-xl relative flex flex-row justify-center h-fit">
            <div className="z-10 flex flex-col justify-between items-center text-white h-full p-2 md:p-10 w-full bg-[#292A35]/60 rounded-xl">
                <h1 className="text-xl text-start md:text-3xl font-bold w-full pb-2">
                    Банки и крышки оптом
                </h1>
                <div className="flex flex-row justify-between gap-5 w-full h-full">
                    <div className="flex flex-col justify-center h-full w-full">
                        <div className="flex flex-col gap-3">
                            <div className="flex flex-row items-center gap-2 md:text-lg text-sm">
                                <FiCheckCircle size="25px"/>
                                <p className="el">Более 3 млн. стеклянных банок сейчас в наличии</p>
                            </div>
                            <div className="flex flex-row items-center gap-2 md:text-lg text-sm">
                                <FiCheckCircle size="25px"/>
                                <p>Более 15 млн. крышек сейчас в наличии</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2 md:gap-5 justify-end h-full flex-shrink-0">
                        <div className="flex flex-col bg-white rounded-xl p-2 md:p-5 ">
                            <p className="font-light text-sm md:text-lg text-black">Свой склад площадью</p>
                            <h1 className="text-lg md:text-[25px] font-semibold text-[#00B956]">1800 м²</h1>
                        </div>
                        <div className="flex flex-col bg-white rounded-xl p-2 md:p-5 flex-shrink-0">
                            <p className="font-light text-sm md:text-lg text-black">Работаем</p>
                            <h1 className="text-lg md:text-[25px] font-semibold text-[#00B956]">с 2006 года</h1>
                        </div>
                    </div>
                </div>
            </div>
            <img src="/home/home.png" alt="preview" className="z-0 w-full absolute h-full object-fill"/>
        </div>
    )
}