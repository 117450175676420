import {FaWhatsapp} from "react-icons/fa6";

export default function ContactBlock() {
    return (
        <div className="flex flex-col items-end w-full">
            <div className="bg-white mt-14 w-full flex-shrink-0">
                <div className="relative p-5 md:p-10">
                    <div className="flex gap-5 flex-col w-full">
                        <p className="text-sm text-black/50">Звоните. Сориентируем по стоимости
                            доставки, <br/> срокам и
                            условиям работы.</p>
                        <h1 className="my-10 font-bold text-4xl">8 925 050-27-77</h1>
                        <div
                            className="border-2 max-w-[300px] rounded-xl border-black/10 hover:bg-green/20 hover:border-green/20 hover:scale-105 active:scale-95 transition cursor-pointer px-5 py-3">
                            <div className="flex flex-row items-center gap-2">
                                <FaWhatsapp size="25px" className="text-green"/>
                                <p className="text-green">Задать вопрос в WhatsApp</p>
                            </div>
                        </div>
                    </div>
                    <div className="absolute flex md:left-[50%] bottom-0 flex-shrink-0">
                        <img src="/home/contact.png" alt="contact" className="hidden md:block w-96"/>
                    </div>
                </div>
            </div>
        </div>
    )
}