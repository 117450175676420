import React, {useState} from 'react';
import Header from "../components/Header";
import HomeVideo from "../components/HomePage/HomeVideo";
import HomePrices from "../components/HomePage/HomePrices";
import StorageInfo from "../components/HomePage/StorageInfo";
import Contact from "../components/HomePage/Contact";
import ContactBlock from "../components/HomePage/ContactBlock";
import MapContainer from "../components/HomePage/Map";
import Footer from "../components/HomePage/Footer";
import TreeObject from "../components/HomePage/Tree";
import {useMediaQuery} from "@react-hook/media-query";
import {motion} from "framer-motion";
import ButtonCatalog from "../components/HomePage/ButtonCatalog";


const HomePage = () => {
    const isMobile = useMediaQuery('(max-width: 1450px)');
    const [isOpen, setOpen] = useState(false);
    return (
        <div
            className="flex flex-row justify-center gap-1 px-2 max-w-[100dvw] items-start overflow-x-hidden h-dvh overflow-y-auto">
            <TreeObject isOpen={isOpen} setOpen={setOpen} button={true}/>
            <motion.div
                animate={{
                    opacity: isOpen ? 0 : 100,
                    overflow: isOpen ? 'hidden' : 'auto',
                    display: isOpen ? 'none' : 'flex',
                    transition: {duration: 0.3}
                }}
                className="flex flex-col justify-center max-w-[100dvw]">
                <div className="flex flex-col gap-5 p-4 mb-14 max-w-[950px] w-full">
                    <Header isOpen={isOpen} setOpen={setOpen}/>
                    <HomeVideo/>
                    <ButtonCatalog/>
                    <HomePrices/>
                    <StorageInfo/>
                    <ContactBlock/>
                    <Contact/>
                    <MapContainer/>
                    <Footer/>
                </div>
            </motion.div>
        </div>
    );
};

export default HomePage;
