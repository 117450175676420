export default function Footer() {
    return (
        <div className="flex flex-row justify-center md:justify-start text-sm font-thin text-black/50 max-w-[100dvw]">
            <div className="flex flex-col w-full text-center md:text-start md:flex-row justify-between gap-2 md:gap-32">
                <p>© 2006 - 2024, Деметра</p>
                <p>Политика конфиденциальности</p>
                <p>Сделано в студии ANSARA</p>
            </div>
        </div>
    )
}