import {IoWalletOutline} from "react-icons/io5";
import {MdOutlineCurrencyRuble} from "react-icons/md";
import {LuBadgePercent} from "react-icons/lu";
import {PiArrowBendRightDownLight} from "react-icons/pi";
import {useNavigate} from "react-router-dom";

export default function HomePrices() {
    const nav = useNavigate()
    return (
        <div className="flex flex-col rounded-xl bg-white gap-5 p-5 px-10 w-full">
            <div className="flex flex-row md:items-start gap-5">
                <IoWalletOutline className="text-[#00B956] mt-1" size="25px"/>
                <p className="text-[20px] font-thin">У нас разные цены в зависимости <br/> от общей суммы заказа</p>
            </div>
            <div className="flex flex-col md:flex-row">
                <div className="flex flex-row items-start gap-3">
                    <div className="border-2 rounded-full border-[#00B956] p-[0.5px] mt-1">
                        <MdOutlineCurrencyRuble
                            className="text-[#00B956]" size="20px"/>
                    </div>
                    <div className="flex flex-col gap-1">

                        <p className="text-[18px] md:text-[20px] text-[#00B956] flex flex-row items-center">до 10
                            000 <MdOutlineCurrencyRuble/></p>
                        <div>
                            <p className="font-thin">Стандартные цены</p>
                            <p className="text-black/50 font-thin">Скидок нет</p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row items-start gap-3">
                    <LuBadgePercent className="text-[#00B956] mt-1" size="25px"/>
                    <div className="flex flex-col gap-1">
                        <div className="text-[18px] md:text-[20px] text-[#00B956] flex flex-row items-center">
                            <p>от 10 000</p>
                            <MdOutlineCurrencyRuble/>
                            <p> до 100 000</p>
                            <MdOutlineCurrencyRuble/>
                        </div>

                        <div>
                            <p className="font-thin">Цены для мелкого опта</p>
                            <p className="text-black/50 font-thin">Скидки от 3% до 6%</p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row items-start gap-3">
                    <LuBadgePercent className="text-[#00B956] mt-1" size="25px"/>
                    <div className="flex flex-col gap-1">
                        <p className="text-[18px] md:text-[20px] text-[#00B956] flex flex-row items-center">от 100
                            000 <MdOutlineCurrencyRuble/></p>
                        <div>
                            <p className="font-thin">Цены для крупного опта</p>
                            <p className="text-black/50 font-thin">Скидки от 5% до 14%</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full border-b-[1px] border-black/20"></div>
            <div onClick={() => nav('/order')}
                 className="flex flex-row items-center gap-1 text-black/50 hover:text-black active:scale-95 transition cursor-pointer">
                <p className="text-sm font-thin">Цена за штуку в карточке товара меняется в зависимости от общей суммы
                    заказа. Попробуйте</p>
                <PiArrowBendRightDownLight className="mt-2 size-10 md:size-5"/>
            </div>
        </div>
    )
}