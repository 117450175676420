import React from "react";
import {motion} from "framer-motion";
import {FaListUl} from "react-icons/fa6";
import {GrAppsRounded} from "react-icons/gr";
import {MdOutlineCurrencyRuble} from "react-icons/md";
import {Ripple} from 'primereact/ripple';
import {useMediaQuery} from "@react-hook/media-query";


function OrderHeader({amount, isChecked, setIsChecked, setOpen}) {
    const isMobile = useMediaQuery('(max-width: 767px)');
    const toggleSwitch = () => {
        setIsChecked(!isChecked);
    };

    return (<div className="sticky pt-4 top-0 bg-[#f5f4f2] shadow-[0_10px_15px_#f5f4f2] z-20">
        <div className={`flex flex-row gap-2 ${isMobile ? 'justify-end' : 'justify-between'}`}>
            {!isMobile && (<div className="flex flex-col gap-2">
                <p className="font-thin">Вид отображения</p>
                <div
                    className="px-1 py-[6px] mt-3 border-black/20 border-[1px] cursor-pointer flex items-center bg-white rounded-md"
                    onClick={toggleSwitch}
                >
                    <motion.div
                        className="p-2 w-full text-lg font-thin h-full relative flex flex-row gap-10 justify-between"
                    >
                        <div className="flex justify-center items-center w-full h-full">
                            <FaListUl color={!isChecked ? "#00B956" : "black"} size={25}/>
                        </div>
                        <div className="flex justify-center items-center w-full h-full">
                            <GrAppsRounded color={isChecked ? "#00B956" : "black"} size={25}/>
                        </div>
                        <motion.div
                            className="w-[50%] h-[100%] rounded-md bg-green/30 absolute left-0 top-0"
                            animate={{x: isChecked ? "100%" : "0%"}}
                            transition={{type: "spring", duration: 0.33, bounceDamping: 0.2}}
                        />
                    </motion.div>
                </div>
            </div>)}
            <div className="flex flex-col gap-2 w-max pr-5 md:pr-0 items-end">
                <p className="flex flex-row gap-1 font-thin text-lg">
                    Общая сумма заказа: <span className="font-semibold flex flex-row items-center ">{amount}
                    <MdOutlineCurrencyRuble/></span>
                </p>
                <div
                    className="px-5 md:px-1 py-1 gap-[11.5rem] flex-row justify-between cursor-pointer flex items-center rounded-md">
                    <div className="hidden md:flex gap-6 flex-row h-full cursor-pointer items-center bg-white rounded-md border-[1px] p-1 border-black/20">
                        <div
                            className={`flex flex-col w-[115px] h-full justify-center p-2 rounded-md ${amount < 10000 ? "bg-green/20" : ""}`}>
                            <p className="flex flex-row items-center font-thin">до 10 000 <MdOutlineCurrencyRuble/></p>
                        </div>
                        <div
                            className={`flex flex-col w-[115px] h-full justify-center p-2 rounded-md ${amount < 100000 && amount >= 10000 ? "bg-green/20" : ""}`}>
                            <p className="flex flex-row items-center font-thin justify-center">от 10
                                000 <MdOutlineCurrencyRuble/></p>
                            <p className="flex flex-row items-center font-thin justify-center">до 100
                                000 <MdOutlineCurrencyRuble/></p>
                        </div>
                        <div
                            className={`flex flex-col w-[115px] h-full justify-center p-2 rounded-md ${amount >= 100000 ? "bg-green/20" : ""}`}>
                            <p className="flex flex-row items-center font-thin">от 100 000 <MdOutlineCurrencyRuble/></p>
                        </div>
                    </div>
                    <button
                        onClick={amount > 0 ? () => setOpen(true) : null}
                        disabled={!amount > 0}
                        className="flex disabled:opacity-40 flex-col p-ripple bg-green active:scale-95 hover:scale-105 transition px-4 h-full w-60 items-center text-white justify-center rounded-md"
                    >
                        <Ripple pt={{root: {style: {background: 'rgb(188,236,193)'}}}}/>
                        <p className="text-lg font-semibold">Оформить заказ</p>
                    </button>
                </div>
            </div>
        </div>
    </div>);
}

export default OrderHeader;
