import TreeObject from "../components/HomePage/Tree";
import React, {useEffect, useState} from "react";
import Header from "../components/Header";
import OrderHeader from "../components/Order/OrderHeader";
import PriceDash from "../components/Order/PriceDash";
import PriceTable from "../components/Order/PriceTable";
import {MdCurrencyRuble} from "react-icons/md";
import {MdNoPhotography} from "react-icons/md";
import {Button} from "primereact/button";
import {FiMinus, FiPlus} from "react-icons/fi";
import Modal from "../components/Modal";
import {Ripple} from "primereact/ripple";
import {useMediaQuery} from "@react-hook/media-query";
import axios from "axios";
import {ProgressSpinner} from "primereact/progressspinner";


function Products() {
    const [amount, setAmount] = useState(0)
    const isMobile = useMediaQuery('(max-width: 767px)');
    const [isOpen, setOpen] = useState(false)
    const [banks, setBanks] = useState(null)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [dashView, setView] = useState(false)
    const [description, setDescription] = useState(null)
    const [orderList, setOrderList] = useState(null)
    useEffect(() => {
        if (localStorage.getItem('view')) {
            setView(JSON.parse(localStorage.getItem('view')))
        }

        async function getData() {
            axios.get('https://api.ansaratracker.store/api/grouped-products/').then((response) => setBanks(response.data))
        }
        getData()
    }, [])

    const increment = () => {
        setDescription(prev => ({...prev, orderCount: prev.orderCount + 1}))
    };


    const decrement = () => {
        setDescription(prev => prev.orderCount > 0 ? ({...prev, orderCount: prev.orderCount - 1}) : prev.orderCount)
    };

    const handleChange = (value) => {
        const newValue = value.slice(0, value.length - 4)
        if (!isNaN(newValue) && newValue.trim() !== '') {
            setDescription(prev => ({...prev, orderCount: Number(newValue)}))
        } else {
            setDescription(prev => ({...prev, orderCount: 0}))
        }
    }

    const currentPrice = (item, index) => {
        if (!amount) {
            return 0
        }
        return item.price1[index]
    }

    useEffect(() => {
        if (banks) {
            const orderList = banks.map((x) => {
                return x.items.filter((y) => y.orderCount > 0).length > 0 ? x.items.filter((y) => y.orderCount > 0) : null
            }).filter((x) => x)[0]
            setOrderList(orderList)
            if (orderList) {
                localStorage.setItem('orderList', JSON.stringify(orderList))
            }
            if (banks.length > 0) {
                //localStorage.setItem('banks', JSON.stringify(banks))
            }
        }
    }, [banks])
    const footerMessage = () => {
        if (amount < 10000) {
            return 'Действует обычная цена (для заказов до 10 000)'
        } else if (amount > 9999 && amount < 100000) {
            return 'Действует цена для мелкого опта (для заказов от 10 000 до 100 000)'
        } else {
            return 'Действует цена для крупного опта (для заказов от 100 000 рублей)'
        }
    }

    const getTotalPrice = () => {
        let total = 0
        if (orderList) {
            let price = 1
            if (amount > 10000 && amount < 100000) {
                price = 2
            }
            if (amount > 99999) {
                price = 3
            }

            orderList.forEach((item) => {
                if (price === 1) {
                    total += item.orderCount * item.price1[0]
                } else if (price === 2) {
                    total += item.orderCount * item.price2[0]
                } else if (price === 3) {
                    total += item.orderCount * item.price3[0]
                }
            })

        }
        return total
    }

    const handleOrder = () => {
        axios.post('https://api.ansaratracker.store/api/order/', {
            'name': name,
            'phone': phone,
            'email': email,
            'order': orderList,
            'amount': amount
        })
        if (name && phone && email) {
            console.log(
                `Имя: ${name}\nТелефон: ${phone}\nПочта: ${email}`
            )
        }
    }

    const handleDecrement = (item, index) => {
        const newOrder = [...orderList];
        newOrder[index].orderCount -= 1
        setOrderList(newOrder)
    }
    const handleEncrement = (item, index) => {
        const newOrder = [...orderList];
        newOrder[index].orderCount += 1
        setOrderList(newOrder)
    }

    const handleChangeOrder = (item, index, value) => {
        const newOrder = [...orderList];
        const count = parseInt(value.replace(' шт.', ''))
        if (count > 0) {
            newOrder[index].orderCount = count
        }
        setOrderList(newOrder)
    }

    useEffect(() => {
        let price = 0
        if (orderList) {
            orderList.forEach((item) => {
                price += item.orderCount * item.price1
            })
            setAmount(price)
        }
    }, [orderList])

    useEffect(() => {
        if (isMobile) {
            setView(true)
        }
    }, [isMobile])

    if (!banks) return <ProgressSpinner style={{width: '50px', height: '50px', stroke: "#00B956FF"}} strokeWidth="4"
                                        fill={"rgba(0,0,0,0)"} animationDuration=".5s"
                                        className="absolute left-1/2 top-1/2"/>

    return (
        <div className="flex flex-row items-start justify-center gap-5 w-full px-5">
            <div className="sticky top-0">
                <TreeObject/>
            </div>
            <div className="flex flex-col justify-center gap-5 pt-5 mb-14 w-[70%]">
                <Header/>
                <OrderHeader amount={amount} isChecked={dashView} setIsChecked={setView} setOpen={setOpen}/>
                {dashView ?
                    <PriceDash setDescription={setDescription} amount={amount} setAmount={setAmount} banks={banks}
                               setBanks={setBanks}/> :
                    <PriceTable setDescription={setDescription} amount={amount} setAmount={setAmount} banks={banks}
                                setBanks={setBanks}/>}
            </div>
            {description && !isOpen ? (
                <Modal setOpen={setDescription} body={<div className="flex flex-row gap-5">
                    <div>
                        {description.images ?
                            <img
                                src={'http://api.ansaratracker.store' + description.images[0].image}
                                alt={'bank_image'} className="rounded-md h-[700px] w-[700px]"/>
                            :
                            <div className="h-full w-96 flex justify-center items-center rounded-md bg-[#f5f4f2]">
                                <MdNoPhotography size={100} className="opacity-20"/>
                            </div>}
                    </div>
                    <div className="flex flex-col gap-7">
                        <p className="font-semibold text-lg">{description.name}</p>
                        <div className="flex flex-col">
                            <p className="text-black/30">{description.quantity_in_pack} шт. в упаковке</p>
                            <div className="flex flex-row items-center">
                                <p>{currentPrice(description, 1)}</p>
                                <MdCurrencyRuble/>
                                <p>за упаковку</p>
                            </div>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="flex flex-row gap-4">
                                <div className="flex flex-row border-2 rounded-xl py-2 border-black/20">
                                    <Button icon={<FiMinus className="text-green active:scale-95"/>}
                                            className="active:outline-none outline-none shadow-none flex-shrink-0"
                                            onClick={() => decrement()}/>
                                    <input className="max-w-[50px] z-0"
                                           onChange={(event) => handleChange(event.target.value)}
                                           value={`${description.orderCount} шт.`}/>
                                    <Button icon={<FiPlus className="text-green active:scale-95"/>}
                                            className="shadow-none flex-shrink-0"
                                            onClick={() => increment()}/>
                                </div>
                                <div className="flex flex-row items-center">
                                    <p>{currentPrice(description, 1)}</p>
                                    <MdCurrencyRuble/>
                                    <p>/шт</p>
                                </div>
                            </div>
                            <p className="text-black/30 text-sm">{footerMessage()}</p>
                        </div>
                        <div className="flex flex-col text-sm">
                            <h1 className="text-lg font-semibold">Характеристики</h1>
                            <div className="flex flex-row items-center gap-1">
                                <p className="text-black/30">Материал: </p>
                                <p>{description.material}</p>
                            </div>
                            <div className="flex flex-row items-center gap-1">
                                <p className="text-black/30">Объем: </p>
                                <p>{description.volume} л.</p>
                            </div>
                            <div className="flex flex-row items-center gap-1">
                                <p className="text-black/30">Кол-во в упаковке: </p>
                                <p>{description.quantity_in_pack}</p>
                            </div>
                            <div className="flex flex-row items-center gap-1">
                                <p className="text-black/30">Тип крышки: </p>
                                <p>{description.type_of_top}</p>
                            </div>
                            <div className="flex flex-row items-center gap-1">
                                <p className="text-black/30">Материал крышки: </p>
                                <p>{description.material_of_top}</p>
                            </div>
                            <div className="flex flex-row items-center gap-1">
                                <p className="text-black/30">Диаметр крышки: </p>
                                <p>{description.diameter_of_top}</p>
                            </div>
                            <div className="flex flex-row items-center gap-1">
                                <p className="text-black/30">Брендирование: </p>
                                <p>{description.branding ? 'Возможно' : 'Нет'}</p>
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <h1 className="text-lg font-semibold">Описание</h1>
                            <p className="text-md font-thin">{description.description}</p>
                        </div>
                    </div>
                </div>}/>
            ) : null}
            {isOpen && orderList ? (
                <Modal setOpen={setOpen} title={<h1 className="text-2xl">Оформление заказа</h1>} body={
                    <div className="flex flex-col gap-5">
                        <div className="flex flex-row items-center gap-5">
                            <input value={name} onChange={(e) => setName(e.target.value)} className="orderFinish"
                                   placeholder="Введите ваше имя"/>
                            <input value={phone} onChange={(e) => setPhone(e.target.value)} className="orderFinish"
                                   placeholder="Введите номер телефона"/>
                            <input value={email} onChange={(e) => setEmail(e.target.value)} className="orderFinish"
                                   placeholder="Введите email"/>
                            <button
                                onClick={handleOrder}
                                className="flex disabled:opacity-40 flex-col p-ripple bg-green active:scale-95 hover:scale-105 transition px-4 py-3 text-white justify-center rounded-md"
                            >
                                <Ripple pt={{root: {style: {background: 'rgb(188,236,193)'}}}}/>
                                <p>Оформить заказ</p>
                            </button>
                        </div>
                        <div className="flex flex-col">
                            <div className="flex flex-col max-h-[50dvh] overflow-y-auto">
                                {orderList.map((x, index) => (
                                    <div
                                        className="flex flex-row w-full justify-between py-5 items-center first:border-t-2 border-b-2 border-black/10 px-5">
                                        <img src={x.img} alt={x.img}/>
                                        <div>
                                            <p>{x.name}</p>
                                            <p className="text-black/30">{x.quantity_in_pack} шт. в упаковке</p>
                                        </div>
                                        <div className="flex flex-row items-center">
                                            <p>{currentPrice(x, 0)}</p>
                                            <MdCurrencyRuble/>
                                            <p>/шт.</p>
                                        </div>
                                        <div
                                            className="flex flex-row relative overflow-clip items-center gap-1 rounded-md border-[1px] w-[150px] border-black/30 h-full justify-between flex-shrink-0">
                                            <button className="flex-shrink-0 w-6 h-6 justify-center items-center flex">
                                                <FiMinus className="text-green active:scale-95" onClick={() => {
                                                    handleDecrement(x, index)
                                                }}/></button>
                                            <input className="w-full flex-shrink-1 text-center"
                                                   onChange={(event) => handleChangeOrder(x, index, event.target.value)}
                                                   value={`${x.orderCount} шт.`}/>
                                            <button className="flex-shrink-0 w-6 h-6 justify-center items-center flex">
                                                <FiPlus className="text-green active:scale-95" onClick={() => {
                                                    handleEncrement(x, index)
                                                }}/></button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="flex flex-row justify-between items-center px-10 py-2">
                                <p className="text-sm text-black/30">{footerMessage()}</p>
                                <div className="flex flex-col">
                                    <p className="text-black/30">Итого</p>
                                    <div className="flex flex-row items-center">
                                        <p className="text-end font-semibold">{getTotalPrice()}</p>
                                        <MdCurrencyRuble/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }/>) : null}
        </div>)
}

export default Products