import TreeObject from "../components/HomePage/Tree";
import React from "react";
import Header from "../components/Header";
import Contact from "../components/HomePage/Contact";
import {MdMailOutline} from "react-icons/md";
import {FiTruck} from "react-icons/fi";

export default function Deliver() {
    return <div className="flex flex-row items-start justify-center gap-5 w-full px-5">
        <div className="sticky top-0">
            <TreeObject/>
        </div>
        <div className="flex flex-col justify-center gap-5 pt-5 mb-14 w-[70%]">
            <Header/>
            <div className="flex flex-col md:flex-row gap-5 w-full pt-14">
                <div className="flex flex-row items-start gap-1">
                    <FiTruck size="25px" className="text-green flex-shrink-0"/>
                    <div className="flex flex-col gap-1">
                        <p className="font-bold text-xl text-green">Доставка по Москве и МО</p>
                        <p>
                            Доставляем своим транспортом в кратчайшие сроки (от 2часов). Стоимость доставки
                            зависит от объёма и регулярности заказов.
                        </p>
                    </div>
                </div>
                <div className="flex flex-row items-start gap-1">
                    <FiTruck size="25px" className="text-green flex-shrink-0"/>
                    <div className="flex flex-col gap-1">
                        <p className="font-bold text-xl text-green">Доставка по всей России</p>
                        <p>
                            Доставляем до транспортной компании.
                            Работаем с компаниямит СДЕК, Деловые Линии, ПЭК. Срок доставки - от 3 дней.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}