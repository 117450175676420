import {MdPhone, MdMailOutline} from "react-icons/md";
import {FiTruck} from "react-icons/fi";
import {PiMapPinSimpleAreaBold} from "react-icons/pi";


export default function Contact() {
    return (
        <div className="mt-14 flex justify-center  max-w-[873px] w-100%">
            <div className="flex flex-col justify-center gap-10">
                <h1 className="text-3xl font-bold">Контакты</h1>
                <div className="flex flex-col md:flex-row justify-between text-wrap whitespace-break-spaces gap-10">
                    <div className="flex flex-col gap-5 w-full md:w-1/2">
                        <div className="flex flex-row items-start gap-1">
                            <PiMapPinSimpleAreaBold size="25px" className="text-green flex-shrink-0"/>
                            <div className="flex flex-col gap-1">
                                <p className="font-bold text-xl text-green">Самовывоз</p>
                                <p className="text-black/50">
                                    Адрес нашего склада:
                                </p>
                                <p className="select-text">
                                    г. Балашиха, квартал Западная Промзона,<br/>шоссе Энтузиастов, 2.
                                </p>
                                <p className="text-black/50">Время работы:</p>
                                <p className="select-text">пн-пт: с 9:00 до 18:00, сб: с 9:00 до 14:00</p>
                            </div>
                        </div>
                        <div className="flex flex-row items-start gap-1">
                            <MdPhone size="25px" className="text-green flex-shrink-0"/>
                            <div className="flex flex-col gap-1">
                                <p className="font-bold text-xl text-green">Телефоны</p>
                                <p className="select-text">
                                    8 (925) 050-27-77<br/>
                                    8 (903) 000-29-72
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-row items-start gap-1">
                            <MdMailOutline size="25px" className="text-green flex-shrink-0"/>
                            <div className="flex flex-col">
                                <p className="font-bold text-xl text-green">Почта</p>
                                <p className="select-text">
                                    banki-kryshki@bk.ru
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-5 w-full md:w-1/2">
                        <div className="flex flex-row items-start gap-1">
                            <FiTruck size="25px" className="text-green flex-shrink-0"/>
                            <div className="flex flex-col gap-1">
                                <p className="font-bold text-xl text-green">Доставка по Москве и МО</p>
                                <p>
                                    Доставляем своим транспортом в кратчайшие сроки (от 2часов). Стоимость доставки
                                    зависит от объёма и регулярности заказов.
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-row items-start gap-1">
                            <FiTruck size="25px" className="text-green flex-shrink-0"/>
                            <div className="flex flex-col gap-1">
                                <p className="font-bold text-xl text-green">Доставка по всей России</p>
                                <p>
                                    Доставляем до транспортной компании.
                                    Работаем с компаниямит СДЕК, Деловые Линии, ПЭК. Срок доставки - от 3 дней.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
