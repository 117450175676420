import { MdCurrencyRuble, MdNoPhotography } from 'react-icons/md';
import { FiMinus, FiPlus } from 'react-icons/fi';

function PriceDash({ amount, setAmount, banks, setBanks, setDescription }) {
  const getActualPrice = (item, index) => {
    if (amount >= 100000)
      return index === 0
        ? parseInt(item.price3)
        : parseInt(item.price3) * item.quantity_in_pack;
    if (amount >= 10000)
      return index === 0
        ? parseInt(item.price2)
        : parseInt(item.price2) * item.quantity_in_pack;
    return index === 0
      ? parseInt(item.price1)
      : parseInt(item.price1) * item.quantity_in_pack;
  };

  const updateAmount = (newBanks) => {
    const totalAmount = newBanks.reduce((total, bank) => {
      return (
        total +
        bank.items.reduce((sum, item) => {
          return sum + item.orderCount * getPricePerUnit(item);
        }, 0)
      );
    }, 0);
    setAmount(totalAmount);
  };

  const getPricePerUnit = (item) => {
    if (item.orderCount <= 0) return 0;
    return item.price1;
  };

  const handleIncrement = (bankIndex, itemIndex, value) => {
    const newBanks = [...banks];
    newBanks[bankIndex].items[itemIndex].orderCount += value;
    setBanks(newBanks);
    updateAmount(newBanks);
  };

  const handleDecrement = (bankIndex, itemIndex, value) => {
    const newBanks = [...banks];
    if (newBanks[bankIndex].items[itemIndex].orderCount >= value) {
      newBanks[bankIndex].items[itemIndex].orderCount -= value;
      setBanks(newBanks);
      updateAmount(newBanks);
    }
  };

  const QuantityTemplate = ({ rowData, bankIndex, itemIndex }) => {
    const handleChange = (event, type) => {
      const newBanks = [...banks];
      if (type === 1) {
        if (!isNaN(event.target.value)) {
          newBanks[bankIndex].items[itemIndex].orderCount = event.target.value;
          setBanks(newBanks);
          updateAmount(newBanks);
        }
      }
      if (type === 2) {
        if (!isNaN(event.target.value)) {
          newBanks[bankIndex].items[itemIndex].orderCount =
            event.target.value * newBanks[bankIndex].items[itemIndex].count;
          setBanks(newBanks);
          updateAmount(newBanks);
        }
      }
    };

    return (
      <div className='flex flex-col w-full gap-5'>
        <div className='flex flex-row justify-between w-full'>
          <div className='flex flex-row items-center'>
            <p>{getActualPrice(rowData, 0)}</p>
            <MdCurrencyRuble />
            <p>/ шт.</p>
          </div>
          <div className='flex items-center gap-1 rounded-md border-[1px] w-[150px] border-black/30 h-full justify-between flex-shrink-0'>
            <button
              className='flex-shrink-0 w-6 h-6 justify-center items-center flex'
              onClick={() => handleDecrement(bankIndex, itemIndex, 1)}
            >
              <FiMinus className='text-green active:scale-95' />
            </button>
            <input
              className='w-full flex-shrink-1 text-center'
              onChange={(event) => handleChange(event, 1)}
              value={`${Math.floor(rowData.orderCount)} шт.`}
            />
            <button
              className='flex-shrink-0 w-6 h-6 justify-center items-center flex'
              onClick={() => handleIncrement(bankIndex, itemIndex, 1)}
            >
              <FiPlus className='text-green active:scale-95' />
            </button>
          </div>
        </div>
        <div className='flex flex-row justify-between w-full'>
          <div className='flex flex-row items-center'>
            <p>{getActualPrice(rowData, 1)}</p>
            <MdCurrencyRuble />
            <p>/ упак.</p>
          </div>
          <div className='flex items-center gap-1 rounded-md border-[1px] w-[150px] border-black/30 h-full justify-between flex-shrink-0'>
            <button
              className='flex-shrink-0 w-6 h-6 justify-center items-center flex'
              onClick={() =>
                handleDecrement(bankIndex, itemIndex, rowData.count)
              }
            >
              <FiMinus className='text-green active:scale-95' />
            </button>
            <input
              className='w-full flex-shrink-1 text-center'
              onChange={(event) => handleChange(event, 2)}
              value={`${Math.floor(
                rowData.orderCount / rowData.quantity_in_pack,
              )} уп.`}
            />
            <button
              className='flex-shrink-0 w-6 h-6 justify-center items-center flex'
              onClick={() =>
                handleIncrement(bankIndex, itemIndex, rowData.quantity_in_pack)
              }
            >
              <FiPlus className='text-green active:scale-95' />
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='pr-5 md:pr-0 w-screen md:w-full items-center md:items-start'>
      {banks.map((bank, bankIndex) => (
        <div key={bank.title} className='w-full'>
          <div className='w-full sticky top-36 z-10 py-2 rounded-xl px-4 bg-white/80 backdrop-blur-lg mb-4'>
            <h1 className='text-xl font-semibold'>{bank.title}</h1>
          </div>
          <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 w-full'>
            {bank.items.map((item, itemIndex) => (
              <div
                key={item.title + itemIndex}
                className='flex flex-col bg-white rounded-md gap-5 pt-2 px-2 pb-5'
              >
                <div
                  className='flex w-full items-center justify-center'
                  onClick={() => setDescription(item)}
                >
                  {item.images ? (
                    <img
                      src={
                        'http://api.ansaratracker.store' + item.images[0].image
                      }
                      alt={item.title}
                      className='w-full rounded-md'
                    />
                  ) : (
                    <div className='h-80 w-full flex justify-center items-center rounded-md bg-[#f5f4f2]'>
                      <MdNoPhotography size={70} className='opacity-40' />
                    </div>
                  )}
                </div>
                <div className='flex flex-col justify-center w-full px-2 gap-10 items-start'>
                  <div className='flex flex-col text-sm'>
                    <p className='m-0'>{item.description}</p>
                    <p className='m-0 text-black/30'>
                      {item.quantity_in_pack} шт. в упаковке
                    </p>
                  </div>
                  <QuantityTemplate
                    rowData={item}
                    bankIndex={bankIndex}
                    itemIndex={itemIndex}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default PriceDash;
