export const PlayText = () => {
    return (
        <svg width="141" height="144" viewBox="0 0 141 144" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M124.914 107.849C125.218 107.389 125.413 106.922 125.497 106.448C125.583 105.98 125.562 105.517 125.433 105.06C125.305 104.609 125.075 104.176 124.744 103.76C124.413 103.344 123.984 102.961 123.455 102.611C122.756 102.148 122.077 101.853 121.416 101.728C120.758 101.608 120.144 101.672 119.576 101.919C119.006 102.17 118.504 102.622 118.071 103.275C117.824 103.648 117.623 104.017 117.468 104.384C117.313 104.758 117.18 105.132 117.069 105.506L116.226 104.947C116.313 104.568 116.441 104.193 116.609 103.824C116.773 103.452 117.008 103.036 117.312 102.576C117.874 101.729 118.518 101.136 119.245 100.798C119.973 100.466 120.753 100.37 121.583 100.51C122.412 100.654 123.263 101.016 124.137 101.594C124.767 102.012 125.285 102.481 125.69 103C126.096 103.526 126.377 104.084 126.533 104.674C126.686 105.267 126.702 105.879 126.58 106.509C126.46 107.145 126.187 107.784 125.761 108.426C125.482 108.848 125.171 109.227 124.828 109.563C124.485 109.899 124.132 110.173 123.767 110.385L123.203 109.454C123.501 109.269 123.799 109.045 124.098 108.784C124.398 108.529 124.67 108.217 124.914 107.849Z" fill="white"/>
            <path d="M111.18 110.296L119.78 114.241L119.819 114.202C119.675 114.082 119.503 113.931 119.305 113.749C119.106 113.567 118.892 113.366 118.662 113.145C118.434 112.927 118.206 112.702 117.975 112.468L113.541 107.969L114.311 107.211L121.326 114.329L120.099 115.538L112.01 111.84L111.976 111.873L115.523 120.049L114.305 121.249L107.29 114.13L108.108 113.324L112.599 117.881C112.807 118.092 113.012 118.307 113.213 118.524C113.418 118.745 113.608 118.954 113.784 119.152C113.963 119.353 114.116 119.528 114.244 119.677L114.283 119.639L110.503 110.963L111.18 110.296Z" fill="white"/>
            <path d="M99.2977 125.418C98.8963 124.761 98.6141 124.111 98.4511 123.468C98.2906 122.829 98.2568 122.214 98.3498 121.624C98.4414 121.039 98.6661 120.493 99.024 119.986C99.3819 119.479 99.8817 119.03 100.523 118.638C101.185 118.234 101.826 117.986 102.448 117.895C103.067 117.806 103.658 117.856 104.222 118.045C104.783 118.237 105.31 118.553 105.803 118.994C106.293 119.437 106.738 119.987 107.14 120.645C107.672 121.516 107.995 122.368 108.108 123.201C108.222 124.034 108.099 124.812 107.739 125.533C107.375 126.258 106.751 126.889 105.868 127.429C105.024 127.944 104.204 128.197 103.408 128.187C102.607 128.179 101.859 127.937 101.161 127.46C100.463 126.989 99.8417 126.309 99.2977 125.418ZM106.088 121.271C105.647 120.547 105.161 119.984 104.632 119.581C104.103 119.178 103.538 118.962 102.938 118.933C102.335 118.907 101.704 119.094 101.047 119.496C100.386 119.899 99.9333 120.373 99.689 120.918C99.4409 121.465 99.3772 122.064 99.498 122.717C99.6188 123.369 99.9002 124.057 100.342 124.78C101.014 125.881 101.771 126.599 102.611 126.935C103.45 127.277 104.358 127.15 105.334 126.554C105.995 126.15 106.453 125.675 106.707 125.13C106.962 124.585 107.034 123.986 106.923 123.332C106.811 122.685 106.533 121.998 106.088 121.271Z" fill="white"/>
            <path d="M90.9719 123.472L92.0648 123.058L95.2471 131.443L98.187 130.327L98.5509 131.286L91.5909 133.927L91.2271 132.968L94.1542 131.858L90.9719 123.472Z" fill="white"/>
            <path d="M85.2117 135.541C83.9476 135.797 82.9751 135.736 82.294 135.358C81.6093 134.985 81.1737 134.339 80.9873 133.419C80.9031 133.004 80.8922 132.594 80.9547 132.191C81.0135 131.793 81.1659 131.42 81.4117 131.073C81.6584 130.73 82.0129 130.426 82.4752 130.16C82.9339 129.9 83.5206 129.697 84.2352 129.552L85.4278 129.311L84.6471 125.458L85.7861 125.227L87.771 135.023L85.2117 135.541ZM85.122 134.548L86.4351 134.282L85.626 130.289L84.5607 130.505C83.9354 130.631 83.4286 130.804 83.0404 131.022C82.6477 131.241 82.3767 131.521 82.2274 131.863C82.0791 132.21 82.0556 132.633 82.1569 133.133C82.2891 133.785 82.595 134.228 83.0746 134.461C83.5552 134.698 84.2376 134.727 85.122 134.548Z" fill="white"/>
            <path d="M70.7581 127.043L76.3192 126.85L76.6663 136.838L71.1052 137.031L71.0696 136.006L75.4693 135.854L75.357 132.622L71.2101 132.766L71.1749 131.755L75.3218 131.611L75.1934 127.915L70.7937 128.068L70.7581 127.043Z" fill="white"/>
            <path d="M64.5187 126.548L65.6794 126.687L64.6104 135.592L67.7325 135.967L67.6103 136.985L60.219 136.097L60.3412 135.079L63.4498 135.452L64.5187 126.548Z" fill="white"/>
            <path d="M59.1154 125.694L56.319 135.289L55.2033 134.964L56.393 130.882L54.5685 130.35C53.7022 130.098 53.0432 129.778 52.5914 129.39C52.1339 129.005 51.8492 128.573 51.7372 128.094C51.6195 127.618 51.6365 127.12 51.7883 126.599C52.0382 125.742 52.5193 125.141 53.2316 124.798C53.9427 124.46 54.8736 124.458 56.0242 124.793L59.1154 125.694ZM57.7204 126.327L55.8631 125.786C55.0668 125.554 54.4256 125.531 53.9395 125.717C53.4521 125.907 53.1178 126.313 52.9368 126.934C52.8105 127.367 52.8089 127.739 52.9318 128.05C53.0547 128.362 53.2906 128.627 53.6394 128.848C53.9838 129.067 54.4338 129.257 54.9894 129.419L56.6761 129.911L57.7204 126.327Z" fill="white"/>
            <path d="M40.9119 129.529L38.4913 128.062C37.4233 127.416 36.7171 126.769 36.3728 126.124C36.0285 125.478 36.0889 124.772 36.554 124.004C36.7547 123.673 36.9997 123.413 37.2888 123.226C37.5718 123.041 37.886 122.935 38.2316 122.91C38.5771 122.885 38.9474 122.947 39.3422 123.095L39.3776 123.037C39.0388 122.752 38.7724 122.444 38.5784 122.113C38.3844 121.782 38.2906 121.427 38.2969 121.047C38.3031 120.668 38.4373 120.261 38.6994 119.829C39.0182 119.302 39.4101 118.932 39.8753 118.719C40.3365 118.503 40.846 118.436 41.4037 118.518C41.959 118.604 42.5407 118.831 43.1488 119.199L46.0898 120.981L40.9119 129.529ZM42.1386 125.261L40.5014 124.269C39.7569 123.818 39.168 123.629 38.7347 123.702C38.2975 123.773 37.9361 124.044 37.6504 124.516C37.3552 125.003 37.3143 125.458 37.5274 125.88C37.7367 126.3 38.2136 126.735 38.9581 127.186L40.4315 128.079L42.1386 125.261ZM42.645 124.425L44.5823 121.226L42.8049 120.15C42.0447 119.689 41.4171 119.511 40.9219 119.616C40.4244 119.725 40.0221 120.033 39.7152 120.54C39.5192 120.863 39.4237 121.184 39.4286 121.501C39.4296 121.816 39.5534 122.131 39.7998 122.445C40.04 122.761 40.4251 123.08 40.9553 123.401L42.645 124.425Z" fill="white"/>
            <path d="M30.311 122.579L29.5123 121.842L33.4304 117.598C33.5787 117.437 33.7427 117.263 33.9222 117.075C34.0987 116.89 34.2737 116.711 34.4474 116.536C34.621 116.361 34.7808 116.205 34.9269 116.067C35.0696 115.926 35.1814 115.818 35.2623 115.744L35.2221 115.707L25.4487 118.091L24.5094 117.224L31.2883 109.88L32.0869 110.617L28.2153 114.812C28.0515 114.989 27.875 115.174 27.6859 115.365C27.4938 115.56 27.3078 115.748 27.128 115.929C26.9448 116.107 26.7802 116.265 26.6342 116.404C26.485 116.545 26.3717 116.654 26.2941 116.732L26.3393 116.773L36.1456 114.364L37.0899 115.236L30.311 122.579Z" fill="white"/>
            <path d="M16.7418 107.505L24.0028 102.264L23.2826 101.266L26.2425 99.1294L26.8946 100.033L24.7773 101.561L28.8423 107.193L30.9597 105.664L31.6118 106.568L28.652 108.704L28.2039 108.084C27.5309 108.148 26.8139 108.272 26.053 108.456C25.2857 108.639 24.5126 108.868 23.7336 109.144C22.9482 109.418 22.1877 109.731 21.452 110.082C20.7136 110.43 20.0331 110.803 19.4105 111.202L16.7418 107.505ZM18.266 107.83L19.6584 109.758C20.1406 109.461 20.7057 109.165 21.3538 108.872C21.9981 108.581 22.6813 108.313 23.4032 108.067C24.1224 107.818 24.8337 107.608 25.5369 107.437C26.2375 107.263 26.8864 107.146 27.4837 107.086L24.679 103.2L18.266 107.83Z" fill="white"/>
            <path d="M20.3709 93.2324L22.8026 98.2374L13.8133 102.605L11.3816 97.5999L12.3039 97.1518L14.2278 101.112L17.1361 99.6985L15.3227 95.9663L16.2327 95.5241L18.0461 99.2564L21.3725 97.6402L19.4486 93.6805L20.3709 93.2324Z" fill="white"/>
            <path d="M12.0563 83.4951C12.7989 83.2908 13.5016 83.1992 14.1643 83.2201C14.8227 83.2423 15.4229 83.3797 15.9651 83.6323C16.5016 83.8817 16.9641 84.2485 17.3524 84.7324C17.7408 85.2164 18.0347 85.8209 18.2341 86.546C18.4396 87.293 18.5004 87.9781 18.4167 88.6014C18.3317 89.2204 18.1202 89.7748 17.7824 90.2648C17.4433 90.7504 16.9937 91.1694 16.4337 91.522C15.8725 91.8701 15.2206 92.1464 14.478 92.3506C13.4937 92.6214 12.5855 92.6963 11.7535 92.5754C10.9215 92.4545 10.2083 92.1213 9.61391 91.5758C9.01836 91.0259 8.5834 90.2522 8.30903 89.2548C8.04675 88.3013 8.03049 87.4431 8.26025 86.6804C8.48881 85.9133 8.92846 85.2606 9.5792 84.7224C10.2243 84.181 11.05 83.7719 12.0563 83.4951ZM14.1666 91.1672C14.9839 90.9424 15.6593 90.6313 16.1929 90.2341C16.7264 89.8368 17.0899 89.354 17.2833 88.7856C17.4755 88.2128 17.4695 87.5551 17.2652 86.8125C17.0597 86.0655 16.7292 85.4994 16.2735 85.1143C15.8166 84.7248 15.2579 84.498 14.5974 84.4339C13.937 84.3698 13.1981 84.4501 12.3808 84.6749C11.1373 85.017 10.2381 85.5455 9.68328 86.2606C9.12285 86.9725 8.99432 87.8799 9.2977 88.9828C9.50317 89.7298 9.83277 90.3009 10.2865 90.696C10.7402 91.0911 11.2963 91.3257 11.9548 91.3998C12.6077 91.4708 13.3449 91.3932 14.1666 91.1672Z" fill="white"/>
            <path d="M14.1572 41.7614C13.9089 42.2537 13.7706 42.7404 13.7425 43.2213C13.7123 43.696 13.7882 44.1528 13.9701 44.5916C14.15 45.0243 14.4288 45.4278 14.8066 45.8021C15.1844 46.1764 15.6561 46.5062 16.2217 46.7914C16.9704 47.169 17.68 47.3814 18.3503 47.4285C19.0187 47.4696 19.6204 47.3341 20.1554 47.0221C20.6926 46.706 21.1376 46.198 21.4906 45.4981C21.6917 45.0994 21.8479 44.7086 21.9593 44.3258C22.0687 43.9369 22.1566 43.55 22.2232 43.165L23.1265 43.6206C23.0844 44.0077 23.0016 44.3946 22.8779 44.7814C22.7584 45.1703 22.5744 45.6109 22.3261 46.1033C21.8685 47.0107 21.2986 47.6752 20.6164 48.0967C19.9322 48.5122 19.1693 48.6991 18.3277 48.6574C17.4882 48.6117 16.6005 48.3529 15.6646 47.8809C14.9891 47.5402 14.4198 47.1357 13.9568 46.6674C13.4917 46.193 13.147 45.672 12.9228 45.1047C12.7005 44.5332 12.613 43.9276 12.66 43.2878C12.7051 42.6419 12.901 41.9751 13.2478 41.2874C13.4756 40.8358 13.7399 40.4229 14.0408 40.049C14.3417 39.675 14.6607 39.3612 14.9979 39.1076L15.6675 39.9659C15.3936 40.1851 15.1237 40.442 14.8578 40.7366C14.5898 41.0251 14.3562 41.3667 14.1572 41.7614Z" fill="white"/>
            <path d="M27.5884 37.5873L18.5996 34.6355L18.5652 34.678C18.722 34.7813 18.9092 34.9122 19.1269 35.0706C19.3445 35.229 19.5803 35.405 19.8341 35.5985C20.0843 35.7891 20.337 35.9876 20.5922 36.1939L25.5038 40.1654L24.8247 41.0052L17.0532 34.7214L18.1364 33.3818L26.5902 36.1472L26.6203 36.11L22.1766 28.3851L23.2511 27.0562L31.0226 33.34L30.3005 34.2331L25.3251 30.21C25.0947 30.0238 24.8673 29.834 24.6426 29.6407C24.4145 29.4444 24.2019 29.2579 24.005 29.0811C23.8045 28.9014 23.6324 28.7446 23.4886 28.6108L23.4542 28.6533L28.1859 36.8484L27.5884 37.5873Z" fill="white"/>
            <path d="M37.6043 21.2394C38.0776 21.847 38.4315 22.4609 38.6662 23.0811C38.898 23.6977 39.0012 24.3047 38.9756 24.9023C38.9508 25.4935 38.7894 26.0612 38.4912 26.6054C38.1929 27.1496 37.7472 27.6527 37.154 28.1148C36.5428 28.5908 35.9333 28.9096 35.3254 29.0711C34.7212 29.2298 34.128 29.247 33.546 29.1227C32.9675 28.9956 32.4082 28.741 31.8679 28.3588C31.3312 27.9739 30.8263 27.4776 30.353 26.87C29.7257 26.0646 29.3085 25.2545 29.1013 24.4397C28.8941 23.6248 28.9285 22.8384 29.2046 22.0804C29.4842 21.3195 30.0322 20.6213 30.8483 19.9856C31.6285 19.3779 32.4149 19.034 33.2074 18.9539C34.0036 18.871 34.7749 19.027 35.5214 19.4218C36.2687 19.8102 36.963 20.4161 37.6043 21.2394ZM31.3268 26.1289C31.8477 26.7976 32.3938 27.3023 32.9652 27.6428C33.5366 27.9834 34.1219 28.134 34.721 28.0949C35.3237 28.0529 35.9289 27.7952 36.5365 27.322C37.1477 26.8459 37.5439 26.3236 37.7249 25.7551C37.9096 25.1838 37.905 24.5809 37.7111 23.9463C37.5172 23.3117 37.1598 22.66 36.639 21.9913C35.8465 20.9738 35.0136 20.3459 34.1405 20.1074C33.2682 19.8626 32.3808 20.0917 31.4784 20.7945C30.8672 21.2706 30.466 21.7939 30.275 22.3644C30.0839 22.9349 30.0803 23.5385 30.2642 24.1751C30.4489 24.8053 30.8031 25.4565 31.3268 26.1289Z" fill="white"/>
            <path d="M46.135 22.1592L45.0972 22.6972L40.9698 14.7346L38.178 16.1817L37.7061 15.2713L44.3153 11.8454L44.7872 12.7558L42.0076 14.1966L46.135 22.1592Z" fill="white"/>
            <path d="M50.4653 9.48784C51.692 9.08971 52.6652 9.03979 53.3849 9.33806C54.1075 9.63059 54.6137 10.2233 54.9035 11.1163C55.0343 11.5194 55.0916 11.9248 55.0754 12.3326C55.0622 12.7346 54.9532 13.1221 54.7484 13.4952C54.5423 13.8639 54.2247 14.2066 53.7956 14.5231C53.3694 14.8339 52.8095 15.1018 52.116 15.3269L50.9586 15.7025L52.172 19.4412L51.0666 19.8L47.9815 10.2939L50.4653 9.48784ZM50.6673 10.4644L49.3929 10.878L50.6505 14.7532L51.6844 14.4177C52.2912 14.2208 52.7751 13.9919 53.136 13.731C53.5013 13.4687 53.7387 13.1593 53.8481 12.8027C53.9562 12.4419 53.9315 12.0187 53.7739 11.5332C53.5685 10.9003 53.2143 10.4954 52.7113 10.3185C52.2069 10.1372 51.5255 10.1859 50.6673 10.4644Z" fill="white"/>
            <path d="M65.688 16.2718L60.1862 17.1043L58.6908 7.2227L64.1927 6.39014L64.3461 7.40399L59.9933 8.06268L60.4771 11.2597L64.5798 10.6388L64.7312 11.6392L60.6284 12.26L61.1818 15.9166L65.5346 15.2579L65.688 16.2718Z" fill="white"/>
            <path d="M72.1127 16.0338L70.9438 16.0247L71.013 7.05627L67.8686 7.03199L67.8765 6.00663L75.3206 6.0641L75.3127 7.08946L72.1819 7.06529L72.1127 16.0338Z" fill="white"/>
            <path d="M77.4967 16.2408L79.1811 6.38963L80.3266 6.58549L79.61 10.7766L81.4832 11.0969C82.3726 11.249 83.0639 11.492 83.557 11.826C84.0553 12.1562 84.3874 12.5528 84.5533 13.0158C84.7245 13.475 84.7643 13.9719 84.6729 14.5065C84.5224 15.387 84.1128 16.0382 83.4443 16.4602C82.7765 16.8777 81.8518 16.9854 80.6704 16.7834L77.4967 16.2408ZM78.8104 15.4529L80.7173 15.7789C81.5349 15.9187 82.1746 15.8686 82.6363 15.6285C83.0989 15.384 83.3847 14.9428 83.4938 14.3049C83.5698 13.8602 83.529 13.4903 83.3714 13.1952C83.2138 12.9 82.9492 12.6629 82.5776 12.4838C82.2105 12.3054 81.7417 12.1675 81.1712 12.07L79.4395 11.7739L78.8104 15.4529Z" fill="white"/>
            <path d="M95.0996 10.285L97.6721 11.4647C98.8071 11.9852 99.5826 12.5463 99.9985 13.1482C100.414 13.75 100.435 14.459 100.061 15.275C99.8995 15.6272 99.6858 15.9127 99.4199 16.1317C99.1601 16.3485 98.86 16.4891 98.5195 16.5536C98.1791 16.6181 97.8043 16.5991 97.395 16.4966L97.3665 16.5588C97.7357 16.8033 98.0356 17.0787 98.2661 17.3849C98.4967 17.6912 98.6306 18.0334 98.6678 18.4114C98.705 18.7895 98.6182 19.2084 98.4073 19.6682C98.1508 20.2275 97.8038 20.6399 97.3661 20.9054C96.9326 21.1729 96.4342 21.2978 95.8707 21.28C95.3092 21.2582 94.7053 21.0991 94.0591 20.8027L90.9336 19.3694L95.0996 10.285ZM94.3693 14.6654L96.1091 15.4632C96.9003 15.8261 97.507 15.9463 97.9291 15.824C98.3553 15.7036 98.6833 15.3928 98.9132 14.8916C99.1507 14.3738 99.1394 13.9174 98.8793 13.5224C98.6234 13.1293 98.0998 12.7513 97.3086 12.3884L95.7427 11.6704L94.3693 14.6654ZM93.9618 15.5539L92.4031 18.9528L94.2921 19.8191C95.0999 20.1895 95.7437 20.2943 96.2236 20.1333C96.7055 19.9682 97.0698 19.6164 97.3168 19.0779C97.4745 18.7341 97.5327 18.4048 97.4915 18.0901C97.4545 17.7773 97.2956 17.4788 97.0148 17.1946C96.74 16.9082 96.3209 16.6358 95.7576 16.3774L93.9618 15.5539Z" fill="white"/>
            <path d="M106.528 15.9979L107.405 16.6405L103.99 21.2993C103.86 21.4757 103.717 21.6673 103.56 21.8741C103.405 22.0773 103.251 22.2754 103.099 22.4685C102.946 22.6616 102.805 22.8349 102.675 22.9886C102.549 23.1449 102.45 23.2645 102.378 23.3473L102.422 23.3796L111.865 19.91L112.896 20.6657L106.987 28.7262L106.111 28.0837L109.485 23.48C109.628 23.2852 109.783 23.0821 109.949 22.8706C110.118 22.6554 110.282 22.4476 110.44 22.2472C110.602 22.0495 110.747 21.8738 110.877 21.7201C111.009 21.5628 111.11 21.4414 111.178 21.3559L111.128 21.3195L101.656 24.8182L100.619 24.0584L106.528 15.9979Z" fill="white"/>
            <path d="M121.718 29.4089L115.094 35.435L115.922 36.3452L113.221 38.8016L112.472 37.9774L114.403 36.2202L109.729 31.0826L107.798 32.8399L107.048 32.0157L109.748 29.5592L110.263 30.1255C110.925 29.9858 111.623 29.7817 112.359 29.5132C113.1 29.2451 113.843 28.9301 114.586 28.5684C115.335 28.2071 116.056 27.8104 116.747 27.3786C117.442 26.9501 118.076 26.5026 118.649 26.0361L121.718 29.4089ZM120.167 29.2581L118.566 27.4984C118.12 27.8483 117.592 28.2057 116.981 28.5704C116.374 28.932 115.725 29.2756 115.035 29.6011C114.349 29.9299 113.666 30.2186 112.986 30.4672C112.31 30.7191 111.678 30.9086 111.091 31.0357L114.316 34.5804L120.167 29.2581Z" fill="white"/>
            <path d="M119.737 43.9522L116.749 39.2575L125.181 33.8924L128.169 38.5872L127.303 39.1376L124.94 35.4234L122.212 37.1591L124.44 40.66L123.586 41.2031L121.359 37.7022L118.238 39.6875L120.602 43.4017L119.737 43.9522Z" fill="white"/>
            <path d="M129.216 52.8658C128.501 53.1525 127.813 53.3228 127.152 53.3768C126.496 53.429 125.884 53.3602 125.317 53.1703C124.755 52.983 124.255 52.6707 123.814 52.2336C123.374 51.7965 123.013 51.229 122.734 50.5311C122.445 49.8121 122.307 49.1382 122.32 48.5094C122.335 47.8848 122.483 47.31 122.763 46.7851C123.045 46.2644 123.445 45.7973 123.961 45.3838C124.48 44.9746 125.096 44.6266 125.811 44.3399C126.759 43.9599 127.652 43.783 128.493 43.8093C129.333 43.8356 130.079 44.0862 130.731 44.5612C131.385 45.0404 131.905 45.7601 132.29 46.7202C132.658 47.6381 132.771 48.4889 132.629 49.2727C132.488 50.0607 132.125 50.7588 131.539 51.367C130.959 51.9777 130.185 52.4773 129.216 52.8658ZM126.254 45.4807C125.467 45.7962 124.831 46.1814 124.346 46.6363C123.86 47.0913 123.554 47.612 123.426 48.1986C123.299 48.7894 123.379 49.4422 123.666 50.157C123.955 50.8761 124.347 51.4013 124.843 51.7325C125.341 52.068 125.922 52.2304 126.585 52.2196C127.249 52.2088 127.974 52.0456 128.761 51.7301C129.958 51.25 130.791 50.6234 131.262 49.8503C131.739 49.0797 131.764 48.1636 131.338 47.102C131.05 46.3829 130.658 45.8527 130.162 45.5112C129.667 45.1698 129.088 44.9994 128.425 45C127.769 45.0032 127.045 45.1634 126.254 45.4807Z" fill="white"/>
            <circle cx="70" cy="72" r="44.5" stroke="white" strokeOpacity="0.5"/>
        </svg>


    )
}